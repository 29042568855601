import { useFlags } from 'launchdarkly-react-client-sdk';

import { getQuoteActivePet, useQuote, useQuotesActiveId } from '@pumpkincare/quotes';
import { getIsLoggedIn } from '@pumpkincare/shared';
import { useUserProfile } from '@pumpkincare/user';

function useShowPaperless() {
  const { punks1954UpsellSawToSawIns } = useFlags();

  const { data: profileData } = useUserProfile();
  const { data: quoteData } = useQuote();
  const { activeId } = useQuotesActiveId();
  const activePet = getQuoteActivePet(quoteData)(activeId);

  const isLoggedIn = getIsLoggedIn();
  const hasEmployerWithoutPaperless =
    activePet?.existing_pet_id && !profileData.is_paperless;

  const showPaperlessAAP = punks1954UpsellSawToSawIns
    ? hasEmployerWithoutPaperless
    : false;

  return isLoggedIn ? showPaperlessAAP : true;
}

export default useShowPaperless;
