import { useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';

import {
  BREED_DEFINITION_QUERY,
  isPrevent4,
  PLAN_QUERY,
  POLICY_OPTIONS_QUERY,
  RECOMMENDATIONS_QUERY,
  usePlan,
} from '@pumpkincare/plans';
import {
  COST_QUERY,
  deleteQuotePetPlan,
  getQuoteActivePet,
  getQuoteId,
  getQuotePets,
  getQuotePolicyZipCode,
  getQuoteVet,
  PRICING_QUERY,
  QUOTE_QUERY,
  useMutateQuote,
  useQuote,
  useQuotesActiveId,
  validateName,
} from '@pumpkincare/quotes';
import {
  DOG,
  PET_AGE_OPTIONS,
  sortByKey,
  UP_TO_5_MONTHS,
  useBreeds,
} from '@pumpkincare/shared';
import {
  Body2,
  LoaderButton,
  Modal,
  Select,
  TextField,
  ToggleButton,
  Typography,
} from '@pumpkincare/shared/ui';
import { getUserId, useUssr } from '@pumpkincare/user';
import { VetAttribution } from '@pumpkincare/vets/ui';

import styles from './quote-edit-modal.css';

import femaleCat from './female-cat.png';
import femaleDog from './female-dog.png';
import maleCat from './male-cat.png';
import maleDog from './male-dog.png';

function QuoteEditModal({ onClose }) {
  const queryClient = useQueryClient();

  //quote data
  const { data: quoteData, isFetched: isQuoteFetched } = useQuote();
  const { activeId } = useQuotesActiveId();
  const quoteId = getQuoteId(quoteData);
  const quoteZipCode = getQuotePolicyZipCode(quoteData);
  const quotePets = getQuotePets(quoteData);
  const quoteVet = getQuoteVet(quoteData);
  const activePet = useMemo(
    () => getQuoteActivePet(quoteData)(activeId) ?? {},
    [activeId, quoteData]
  );
  const { petAge, petBreedCode } = activePet;

  const { mutateAsync: mutateQuote } = useMutateQuote();

  const { data: userData } = useUssr();
  const userId = getUserId(userData);

  // local state
  const [petLocal, setPetLocal] = useState({});
  const [zipCode, setZipCode] = useState('');
  const [vetLocal, setVetLocal] = useState({});

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { data: quoteVetPlanData } = usePlan(
    activePet?.petAge,
    activePet?.petBreedSpecies,
    userId,
    quoteVet?.id
  );
  const { data: localVetPlanData } = usePlan(
    activePet?.petAge,
    activePet?.petBreedSpecies,
    userId,
    vetLocal?.id
  );

  // breed data
  const { data: breedsDataRaw } = useBreeds(petLocal.petBreedSpecies);
  const breedsData = useMemo(
    () => sortByKey(breedsDataRaw, 'name'),
    [breedsDataRaw]
  );
  const breedOptions = breedsData.map(breed => {
    const { code, name, species, type } = breed;

    return {
      label: name,
      value: { code, name, species, type },
    };
  });

  const activePetAge = petAge && PET_AGE_OPTIONS.find(obj => obj.value === petAge);
  const activePetBreed =
    petBreedCode && breedOptions.find(obj => obj.value.code === petBreedCode);
  const isNameError = validateName(petLocal.petName) === false;
  const isError = isNameError || !petLocal.petBreedCode;

  useEffect(() => {
    // need this for unit tests mostly
    if (isQuoteFetched) {
      setPetLocal(activePet);
      setZipCode(quoteZipCode);
      setVetLocal(quoteVet);
    }
  }, [activePet, isQuoteFetched, quoteVet, quoteZipCode]);

  function handleNameChange(e) {
    setPetLocal({ ...petLocal, petName: e.target.value });
  }

  function handleBreedChange(option) {
    const { code, name, type } = option.value;

    setPetLocal({
      ...petLocal,

      petBreedCode: code,
      petBreedName: name,
      petBreedType: type,
    });
  }

  function handleAgeChange(option) {
    setPetLocal({ ...petLocal, petAge: option.value });
  }

  function handleGenderChange(e) {
    setPetLocal({ ...petLocal, petGender: e.currentTarget?.value });
  }

  function handleVetClinicChange(data) {
    setVetLocal(data);
  }

  function handleHasVetChange(value) {
    if (value === false) {
      setVetLocal({});
    } else {
      setVetLocal(quoteVet);
    }
  }

  function isChangingPlan(currentPetAge, newPetAge) {
    return (
      (currentPetAge === UP_TO_5_MONTHS && newPetAge !== UP_TO_5_MONTHS) ||
      (currentPetAge !== newPetAge && newPetAge === UP_TO_5_MONTHS)
    );
  }

  function handleUpdateClick() {
    const deletingPep = [];

    setIsSubmitting(true);

    if (vetLocal?.id !== quoteVet?.id)
      // when user changes a vet
      quotePets.forEach(pet => {
        if (isPrevent4(localVetPlanData) || isPrevent4(quoteVetPlanData)) {
          // delete plan if there is PEP 4
          deletingPep.push(deleteQuotePetPlan(quoteId, pet));
        }
      });

    if (deletingPep.length === 0 && isChangingPlan(petAge, petLocal.petAge)) {
      // delete plan if age was changed enough to change the plan
      deletingPep.push(deleteQuotePetPlan(quoteId, activePet));
    }

    Promise.all(deletingPep)
      .then(() => {
        return mutateQuote({
          pets: quotePets.map(pet => {
            return pet.id === activeId ? { ...pet, ...petLocal } : pet;
          }),
          vet: vetLocal,
          zipCode,
        });
      })
      .then(() => {
        onClose();
      })
      .finally(() => {
        setIsSubmitting(false);

        queryClient.invalidateQueries([BREED_DEFINITION_QUERY]);
        queryClient.invalidateQueries([COST_QUERY, quoteId]);
        queryClient.invalidateQueries([PLAN_QUERY, quoteId]);
        queryClient.invalidateQueries([POLICY_OPTIONS_QUERY, quoteId]);
        queryClient.invalidateQueries([PRICING_QUERY, quoteId]);
        queryClient.invalidateQueries([QUOTE_QUERY, quoteId]);
        queryClient.invalidateQueries([RECOMMENDATIONS_QUERY]);
      });
  }

  return (
    <Modal onClose={onClose} disabled={isSubmitting}>
      <div className={styles.container}>
        <h3 className={Typography.h3}>Edit your pet's plan</h3>

        <TextField
          aria-label={'Your Pet’s Name'}
          defaultValue={petLocal.petName}
          error={{
            hasError: isNameError,
          }}
          label={'Your Pet’s Name'}
          onChange={handleNameChange}
          placeholder={'Your Pet’s Name'}
          readOnly={isSubmitting}
          required
        />

        <div className={styles.spacing}>
          {breedOptions.length && activePetBreed ? (
            <Select
              disabled={isSubmitting}
              isSearchable
              onChange={handleBreedChange}
              placeholder={'Select breed or start typing'}
              label={'Breed'}
              options={breedOptions}
              defaultValue={activePetBreed}
              required
              error={{
                hasError: !petLocal.petBreedCode,
              }}
            />
          ) : null}
        </div>

        <div className={styles.spacing}>
          {activePetAge ? (
            <Select
              disabled={isSubmitting}
              label={'Age'}
              defaultValue={activePetAge}
              options={PET_AGE_OPTIONS}
              placeholder={'Age'}
              onChange={handleAgeChange}
              required
            />
          ) : null}
        </div>

        <div className={styles.spacing}>
          <Body2 isBold>Gender</Body2>

          <div className={styles.selectedButton}>
            <ToggleButton
              disabled={isSubmitting}
              onClick={handleGenderChange}
              selected={petLocal.petGender === 'male'}
              variant={'portal'}
              value={'male'}
              label={'Boy'}
              direction={'v'}
              icon={petLocal.petBreedSpecies === DOG ? maleDog : maleCat}
            />

            <ToggleButton
              disabled={isSubmitting}
              onClick={handleGenderChange}
              selected={petLocal.petGender === 'female'}
              variant={'portal'}
              value={'female'}
              label={'Girl'}
              direction={'v'}
              icon={petLocal.petBreedSpecies === DOG ? femaleDog : femaleCat}
            />
          </div>
        </div>

        <div className={styles.spacing}>
          <VetAttribution
            disabled={isSubmitting}
            isPreselect
            message={
              isPrevent4(quoteVetPlanData) || isPrevent4(localVetPlanData) ? (
                <Body2 style={{ color: 'var(--gromitGray)' }}>
                  Changing your vet will remove the wellness plans from all pets on
                  your quote.
                </Body2>
              ) : null
            }
            zipcode={zipCode}
            selectedVet={Object.keys(vetLocal).length ? vetLocal : quoteVet}
            onVetClinicChange={handleVetClinicChange}
            onHasVetChange={handleHasVetChange}
            required={false}
            placeholder={' '}
          />
        </div>

        <div className={styles.spacing}>
          <LoaderButton
            classes={{ root: styles.button }}
            color={'primary'}
            disabled={isError}
            isLoading={isSubmitting}
            onClick={handleUpdateClick}
          >
            Update Pet Details
          </LoaderButton>
        </div>
      </div>
    </Modal>
  );
}
export default QuoteEditModal;
