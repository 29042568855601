/* eslint-disable react/jsx-no-target-blank */
import { useState } from 'react';
import classNames from 'classnames';

import { MC_URL, WWW_URL } from '@pumpkincare/config';
import {
  ARF_MEOW_PHONE_NUMBER,
  CONTACT_EMAIL,
  CONTACT_EMAIL_LINK,
  PHONE_NUMBER_LINK,
} from '@pumpkincare/shared';
import { ButtonStyles, LegalBody, Typography } from '@pumpkincare/shared/ui';

import MobileMenu from '../mobile-menu';

import styles from './nav-bar.module.css';

function NavBar() {
  const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);

  function handleMobileMenuItemClick() {
    setIsHamburgerOpen(!isHamburgerOpen);
  }

  return (
    <nav>
      <div className={styles.topMenuContainer}>
        <div>
          <ul className={styles.menuWrapper}>
            <div className={classNames(Typography.body2, styles.mainMenuContainer)}>
              <div className={styles.menuIcons}>
                <button
                  className={classNames(ButtonStyles.unset, styles.mobileMenuButton)}
                  onClick={handleMobileMenuItemClick}
                >
                  &nbsp;
                </button>

                <a
                  href={WWW_URL}
                  target='_blank'
                  rel='noopener'
                  title='Home'
                  tabIndex='-1'
                  className={styles.logoLink}
                >
                  <img
                    alt='Pumpkin Logo'
                    src={`/assets/images/Pumpkin_Logo_White.svg`}
                    className={styles.logo}
                  />
                </a>
              </div>
            </div>

            {isHamburgerOpen ? (
              <div className={styles.mobileMenuContainer}>
                <MobileMenu onItemClick={handleMobileMenuItemClick} />
              </div>
            ) : null}

            <li className={styles.menuItem}>
              <LegalBody className={styles.legalBody}>
                <a href={PHONE_NUMBER_LINK}>{`${ARF_MEOW_PHONE_NUMBER}`}</a>
              </LegalBody>
            </li>
            <li className={styles.contactEmail}>
              <LegalBody className={styles.legalBody}>
                <a href={CONTACT_EMAIL_LINK}>{CONTACT_EMAIL}</a>
              </LegalBody>
            </li>
            <li className={styles.liDivider}>
              <div className={styles.vDivider}>&nbsp; </div>
            </li>
            <li className={styles.menuItem}>
              <LegalBody className={styles.legalBody}>
                <a
                  href={`${WWW_URL}/underwriting-information/`}
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  Underwriting
                </a>
              </LegalBody>
            </li>
            <li className={classNames(styles.liDivider, styles.hideLogout)}>
              <div className={styles.vDivider}>&nbsp; </div>
            </li>
            <li className={classNames(styles.menuItem, styles.hideLogout)}>
              <LegalBody className={styles.legalBody}>
                <a href={`${MC_URL}/login`} rel='noopener noreferrer'>
                  Login
                </a>
              </LegalBody>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;
