/* eslint-disable react/jsx-no-target-blank */
import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { GaLink } from '@pumpkincare/analytics/ui';
import { BLOG_URL, MC_URL, WWW_URL } from '@pumpkincare/config';
import {
  ARF_MEOW_PHONE_NUMBER,
  PHONE_ARIA_LABEL,
  PHONE_NUMBER_LINK,
} from '@pumpkincare/shared';
import { ButtonStyles, Link, Typography } from '@pumpkincare/shared/ui';

import styles from './mobile-menu.css';

const PET_INSURANCE_LINKS = [
  {
    title: 'Dog Pet Insurance',
    href: `${WWW_URL}/dog-health-insurance`,
    event: 'Click For Dogs',
  },
  {
    title: 'Puppy Pet Insurance',
    href: `${WWW_URL}/puppy-insurance`,
    event: 'Click For Puppies',
  },
  {
    title: 'Cat Pet Insurance',
    href: `${WWW_URL}/cat-health-insurance`,
    event: 'Click For Cats',
  },
  {
    title: 'Kitten Pet Insurance',
    href: `${WWW_URL}/kitten-insurance`,
    event: 'Click For Kittens',
  },
];

const PEP_LINKS = [
  {
    title: 'Preventive Essentials for Dogs & Puppies',
    href: `${WWW_URL}/dog-preventativecare`,
  },
  {
    title: 'Preventive Essentials for Cats & Kittens',
    href: `${WWW_URL}/cat-preventativecare`,
  },
];

const COMPARE_LINKS = [
  { title: 'Compare Pumpkin', href: `${WWW_URL}/compare` },
  { title: 'Trupanion', href: `${WWW_URL}/compare/trupanion` },
  { title: 'Nationwide', href: `${WWW_URL}/compare/nationwide` },
  { title: 'Healthy Paws', href: `${WWW_URL}/compare/healthy-paws` },
  { title: 'Figo', href: `${WWW_URL}/compare/figo` },
  { title: 'Petplan', href: `${WWW_URL}/compare/petplan` },
  { title: 'Embrace', href: `${WWW_URL}/compare/embrace` },
  { title: 'Lemonade', href: `${WWW_URL}/compare/lemonade` },
  { title: 'Pets Best', href: `${WWW_URL}/compare/pets-best` },
  { title: 'Geico', href: `${WWW_URL}/compare/geico` },
  { title: 'Progressive', href: `${WWW_URL}/compare/progressive` },
  { title: 'AKC', href: `${WWW_URL}/compare/akc` },
  { title: 'Metlife', href: `${WWW_URL}/compare/metlife` },
  { title: 'Wagmo', href: `${WWW_URL}/compare/wagmo` },
  { title: 'Pet Assure', href: `${WWW_URL}/compare/pet-assure` },
  { title: 'Pawp', href: `${WWW_URL}/compare/pawp` },
  { title: 'Odie', href: `${WWW_URL}/compare/odie` },
  { title: 'Trusted Pals', href: `${WWW_URL}/compare/trusted-pals` },
  { title: 'Prudent Pet', href: `${WWW_URL}/compare/prudent-pet` },
  { title: 'ManyPets', href: `${WWW_URL}/compare/manypets` },
];

const CLAIMS_LINKS = [
  { title: 'Claims Overview', href: `${WWW_URL}/claims` },
  { title: 'Submit a Claim', href: `${MC_URL}/claim-submission`, icon: true },
];

const ADVICE_LINKS = [
  { title: 'All Advice', href: BLOG_URL },
  { title: 'Pet Parenting', href: `${BLOG_URL}/category/pet-parenting-101/` },
  {
    title: 'Behavior & Training',
    href: `${BLOG_URL}/category/behavior-and-training/`,
  },
  { title: 'Health & Wellness', href: `${BLOG_URL}/category/health-and-wellness/` },
  { title: 'Pet Insurance 101', href: `${BLOG_URL}/category/pet-insurance/` },
  { title: 'Trending Now', href: `${BLOG_URL}/category/trending-now/` },
  { title: 'Dog Breeds', href: `${WWW_URL}/dog-breeds` },
  { title: 'Cat Breeds', href: `${WWW_URL}/cat-breeds` },
];

function MobileMenu({ onItemClick }) {
  const firstElem = useRef();
  const logoElem = useRef();
  const [menuState, setMenuState] = useState({
    unselectAll: false,

    petInsurance: false,
    preventiveCare: false,
    company: false,
    compare: false,
    claims: false,
    advice: false,
  });

  function toggleMenuItem(item) {
    setMenuState(state => ({
      petInsurance: false,
      preventiveCare: false,
      company: false,
      compare: false,
      claims: false,
      advice: false,
      unselectAll: state[item],
      [item]: !state[item],
    }));
  }

  useEffect(() => {
    logoElem.current.focus();
  }, [logoElem]);

  return (
    <>
      <div className={styles.backdrop} onClick={onItemClick}>
        &nbsp;
      </div>

      <div className={styles.root}>
        <div className={styles.logo} ref={logoElem} tabIndex='0'>
          <button
            autoFocus={true}
            className={ButtonStyles.unset}
            onClick={onItemClick}
            ref={firstElem}
          >
            <img alt='hide menu' src='/assets/images/iconImages/chevron.svg' />
          </button>

          <Link
            href={WWW_URL}
            target='_blank'
            rel='noopener'
            onClick={onItemClick}
            title='Home'
            tabIndex='-1'
            className={styles.logoContainer}
          >
            <img
              alt=''
              role='presentation'
              src='/assets/images/Pumpkin_Logo_White.svg'
              className={styles.logoImg}
            />
          </Link>
        </div>

        <div className={styles.menuContainer}>
          <ul className={classNames(Typography.body2, Typography.bold, styles.menu)}>
            <li className={styles.menuItem}>
              <input
                type='radio'
                name='mobileMenu'
                id='unselectAllRadio'
                checked={menuState.unselectAll}
                readOnly
              />
              <input
                type='radio'
                name='mobileMenu'
                id='petInsuranceRadio'
                checked={menuState.petInsurance}
                readOnly
              />

              <label htmlFor='petInsuranceRadio'>
                <button
                  className={ButtonStyles.unset}
                  onClick={() => toggleMenuItem('petInsurance')}
                >
                  Pet Insurance <span className={styles.expandIcon}>&nbsp;</span>
                </button>
              </label>

              <ul className={styles.dropdown}>
                {PET_INSURANCE_LINKS.map(({ title, href, event }) => (
                  <GaLink
                    key={title}
                    data-event={event}
                    data-label='Navigation Bar'
                    data-category='About Pumpkin'
                    href={href}
                    rel='noopener'
                    target='_blank'
                    className={classNames(Typography.legalBody, styles.dropdownItem)}
                    onClick={onItemClick}
                  >
                    {title}
                  </GaLink>
                ))}
              </ul>
            </li>

            <li className={styles.menuItem}>
              <input
                type='radio'
                name='mobileMenu'
                id='preventiveCareRadio'
                checked={menuState.preventiveCare}
                readOnly
              />
              <label htmlFor='preventiveCareRadio'>
                <button
                  className={ButtonStyles.unset}
                  onClick={() => toggleMenuItem('preventiveCare')}
                >
                  Preventive Care <span className={styles.expandIcon}>&nbsp;</span>
                </button>
              </label>

              <ul className={styles.dropdown}>
                {PEP_LINKS.map(({ title, href }) => (
                  <Link
                    key={title}
                    href={href}
                    rel='noopener'
                    target='_blank'
                    className={classNames(Typography.legalBody, styles.dropdownItem)}
                    onClick={onItemClick}
                  >
                    {title}
                  </Link>
                ))}
              </ul>
            </li>

            <li className={styles.menuItem}>
              <input
                type='radio'
                name='mobileMenu'
                id='compareRadio'
                checked={menuState.compare}
                readOnly
              />
              <label htmlFor='compareRadio'>
                <button
                  className={ButtonStyles.unset}
                  onClick={() => toggleMenuItem('compare')}
                >
                  Compare <span className={styles.expandIcon}>&nbsp;</span>
                </button>
              </label>

              <ul className={styles.dropdown}>
                {COMPARE_LINKS.map(({ title, href }) => (
                  <Link
                    key={title}
                    href={href}
                    rel='noopener'
                    target='_blank'
                    className={classNames(Typography.legalBody, styles.dropdownItem)}
                    onClick={onItemClick}
                  >
                    {title}
                  </Link>
                ))}
              </ul>
            </li>

            <li className={styles.menuItem}>
              <input
                type='radio'
                name='mobileMenu'
                id='claimsRadio'
                checked={menuState.claims}
                readOnly
              />
              <label htmlFor='claimsRadio'>
                <button
                  className={ButtonStyles.unset}
                  onClick={() => toggleMenuItem('claims')}
                >
                  Claims <span className={styles.expandIcon}>&nbsp;</span>
                </button>
              </label>

              <ul className={styles.dropdown}>
                {CLAIMS_LINKS.map(({ title, href, icon }) => (
                  <Link
                    key={title}
                    href={href}
                    rel='noopener noreferrer'
                    target='_blank'
                    className={classNames(Typography.legalBody, styles.dropdownItem)}
                    onClick={onItemClick}
                  >
                    {title}
                    {icon ? (
                      <img src='/assets/images/iconImages/chevrons-right-white.svg' />
                    ) : null}
                  </Link>
                ))}
              </ul>
            </li>

            <li className={styles.menuItem}>
              <input
                type='radio'
                name='mobileMenu'
                id='adviceRadio'
                checked={menuState.advice}
                readOnly
              />
              <label htmlFor='adviceRadio'>
                <button
                  className={ButtonStyles.unset}
                  onClick={() => toggleMenuItem('advice')}
                >
                  Advice <span className={styles.expandIcon}>&nbsp;</span>
                </button>
              </label>

              <ul className={styles.dropdown}>
                {ADVICE_LINKS.map(({ title, href }) => (
                  <Link
                    key={title}
                    href={href}
                    target='_blank'
                    rel='noopener noreferrer'
                    className={classNames(Typography.legalBody, styles.dropdownItem)}
                    onClick={onItemClick}
                  >
                    {title}
                  </Link>
                ))}
              </ul>
            </li>
          </ul>

          <div className={styles.menuDivider} />

          <ul className={styles.links}>
            <li className={styles.menuItem}>
              <Link
                href={`${WWW_URL}/underwriting-information`}
                rel='noopener'
                target='_blank'
                onClick={onItemClick}
              >
                Underwriting
              </Link>
            </li>
          </ul>

          <GaLink
            href={PHONE_NUMBER_LINK}
            aria-label={PHONE_ARIA_LABEL}
            data-event='Click Blog'
            data-label='Navigation Bar'
            data-category='About Pumpkin'
            style={{ display: 'block', margin: '0 16px 16px' }}
            tabIndex='-1'
          >
            <button className={classNames(ButtonStyles.blue, styles.callButton)}>
              {ARF_MEOW_PHONE_NUMBER}
            </button>
          </GaLink>

          <span tabIndex='0' onFocus={() => firstElem.current.focus()}>
            &nbsp;
          </span>
        </div>
      </div>
    </>
  );
}

export default MobileMenu;
