import { useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { COUNTRY_OPTIONS, STATE_OPTIONS } from '@pumpkincare/shared';
import {
  Body1,
  InputStyles,
  Select,
  SwitchInput,
  Typography,
} from '@pumpkincare/shared/ui';

import styles from './billing-address-form.css';

function BillingAddressForm({
  address,
  isSameAsShipping,
  onAddressToggle,
  onChange,
  canUseAccountAddress,
}) {
  const countryUS = { value: 'US', label: 'United States of America' };
  const [country, setCountry] = useState(countryUS);

  const textInputClassName = classNames(
    styles.textInput,
    styles.border,
    Typography.body2,
    InputStyles.text
  );

  function handleFieldChangeFactory(field) {
    return e => {
      return onChange({ field, value: e.target.value.replace(/\s+/g, ' ').trim() });
    };
  }

  function handleCountryChange(option) {
    onChange({
      field: address.country.id,
      value: option.value.replace(/\s+/g, ' ').trim(),
    });

    if (country.value !== option.value) {
      onChange({
        field: address.state.id,
        value: '',
      });
    }

    setCountry(option);
  }

  function handleStateChange(option) {
    onChange({
      field: address.state.id,
      value: option.value.replace(/\s+/g, ' ').trim(),
    });
  }

  useEffect(() => {
    onChange({
      field: 'country',
      value: 'US',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {canUseAccountAddress ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            margin: '16px 0 0',
          }}
        >
          <div data-testid='billing-switch-button'>
            <SwitchInput
              checked={isSameAsShipping}
              id='billingFormAddressToggle'
              onChange={onAddressToggle}
            />
          </div>

          <Body1>
            Switch on to use your account address as your billing address.
          </Body1>
        </div>
      ) : null}

      {isSameAsShipping ? null : (
        <div data-testid='billing-form'>
          <Body1 className={styles.inputLabel}>Address</Body1>

          <input
            aria-label={'Address'}
            autoComplete={'address-line1'}
            className={classNames(
              textInputClassName,
              address.address1.error ? styles.error : null
            )}
            data-testid={'address1-input'}
            defaultValue={address.address1.value}
            id={'address1'}
            name={'address1'}
            onChange={handleFieldChangeFactory(address.address1.id)}
          />

          <Body1 className={styles.inputLabel}>Address Line 2 (Optional)</Body1>

          <input
            autoComplete={'address-line2'}
            aria-label={'Address line 2'}
            className={textInputClassName}
            data-testid={'address2-input'}
            defaultValue={address.address2.value}
            id={'address2'}
            name={'address2'}
            onChange={handleFieldChangeFactory(address.address2.id)}
          />

          <Body1 className={styles.inputLabel}>City</Body1>

          <input
            aria-label={'City'}
            autoComplete={'address-level2'}
            className={classNames(
              textInputClassName,
              address.city.error ? styles.error : null
            )}
            data-testid={'city-input'}
            defaultValue={address.city.value}
            id={'city'}
            name={'city'}
            onChange={handleFieldChangeFactory(address.city.id)}
          />

          <Select
            classes={{ label: styles.inputLabel }}
            defaultValue={countryUS}
            id={'country-select'}
            isSearchable
            label={'Country'}
            onChange={handleCountryChange}
            options={COUNTRY_OPTIONS}
            placeholder={'Select a State...'}
          />

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <div className={styles.divColumnLeft}>
              {country.value === countryUS.value ? (
                <Select
                  classes={{ label: styles.inputLabel }}
                  id={'state-select'}
                  label={'State'}
                  onChange={handleStateChange}
                  options={STATE_OPTIONS}
                  placeholder={'Select a State...'}
                />
              ) : (
                <>
                  <Body1 className={styles.inputLabel}>State / Province</Body1>

                  <input
                    aria-label={'State'}
                    autoComplete={'address-level1'}
                    className={classNames(
                      textInputClassName,
                      address.state.error ? styles.error : null
                    )}
                    id={'state'}
                    name={'state'}
                    onChange={handleFieldChangeFactory(address.state.id)}
                  />
                </>
              )}
            </div>

            <div className={styles.divColumnRight}>
              <Body1 className={styles.inputLabel}>Zip Code</Body1>

              <input
                aria-label={'Zip'}
                autoComplete={'postal-code'}
                className={classNames(
                  textInputClassName,
                  address.zipcode.error ? styles.error : null
                )}
                data-testid={'zipCode-input'}
                id={'zipcode'}
                name={'zipcode'}
                onChange={handleFieldChangeFactory(address.zipcode.id)}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

BillingAddressForm.defaultProps = {
  canUseAccountAddress: true,
};

BillingAddressForm.propTypes = {
  address: PropTypes.shape({
    address1: PropTypes.shape({
      error: PropTypes.bool,
      id: PropTypes.string,
      value: PropTypes.string,
    }),
    address2: PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    }),
    city: PropTypes.shape({
      id: PropTypes.string,
      error: PropTypes.bool,
      value: PropTypes.string,
    }),
    country: PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    }),
    state: PropTypes.shape({
      id: PropTypes.string,
      error: PropTypes.bool,
      value: PropTypes.string,
    }),
    zipcode: PropTypes.shape({
      id: PropTypes.string,
      error: PropTypes.bool,
      value: PropTypes.string,
    }),
  }).isRequired,
  isSameAsShipping: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onAddressToggle: PropTypes.func.isRequired,
  canUseAccountAddress: PropTypes.bool,
};

export default BillingAddressForm;
